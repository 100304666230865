// import { useState } from "react";
import {
  NoSectionArrow,
  NoSectionText,
  SectionContent,
  AboutSection,
  AboutTitle,
  AboutImage,
  AboutText,
  ResumeSection,
  ResumeTitle,
  ResumeContainer,
  ResumePage,
  ContactSection,
  ContactTitle,
  ContactText,
  ContactImagesSection,
  ContactImageLink,
} from "../StyledApp";
import Footer from "./Footer";
import { InstaWhiteSVG } from "../svg/instagramWhite";
import { InstaSVG } from "../svg/instagram";
import { ItchSVG } from "../svg/itch-io-brands";
import { LinkedInSVG } from "../svg/linkedin-brands";
import Resume from "../images/Resume.pdf";
import ResumeImage from "../images/ResumeImage.png";

function About({ selected }) {
  return (
    <SectionContent selected={selected === "" ? true : false}>
      <NoSectionArrow>^</NoSectionArrow>
      <NoSectionText>select a section</NoSectionText>
      <AboutSection>
        <AboutImage src="https://media.licdn.com/dms/image/v2/C4E03AQGt3W-K2u2s_g/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1587183845582?e=1749081600&v=beta&t=qPDRNkp0Iu7Jia56u3hdK5f26itaOx_2VvNj5znwXKk&quality=lossless" />
        <div>
          <AboutTitle id="about">About</AboutTitle>
          <AboutText>
            Hi, I'm a full stack software developer based in Manchester with
            five years of experience building scalable and user friendly
            applications. Skilled in TDD, agile development and a strong team
            player. I’m passionate about intuitive design, with seamless
            alignment between developers and designers. Dedicated to continually
            learning and delivering high quality, detail oriented solutions.
            <br />
            <br />
            In my free time, I have worked on several game jams, creating art
            assets, code, and audio to submit alongside many other teams. From
            there, I have won “Best Solo Game,” “Most Commercially Viable,”
            “Best 2D Art,” and 8 other nominations in the top 3.
            <br />
            <br />
            Currently I am working on expanding my portfolio and hope to join a
            team to gain experience working on newer and bigger projects while
            improving my skills.
            <br />
            <br />
            Thanks for visiting my portfolio :D
            <br />
            -Tommy
          </AboutText>
        </div>
      </AboutSection>
      <ResumeSection>
        <div>
          <ResumeTitle id="about">Resume / CV</ResumeTitle>
          <ResumeContainer>
            <a href={Resume} target="_blank" rel="noreferrer">
              <ResumePage src={ResumeImage} />
            </a>
            {/* <a href={Resume} target="_blank" rel="noreferrer">
              <ResumePage src="https://cdn.discordapp.com/attachments/598365774125727764/915932369154899998/unknown.png" />
            </a> */}
          </ResumeContainer>
        </div>
      </ResumeSection>
      <ContactSection>
        <ContactTitle id="contact">Contact</ContactTitle>
        <ContactText>
          {/* Please don't hesitate to get in contact if you have a question,
          looking forward to hearing from you :) */}
          To get in contact please email me at talluff@gmail.com
          <br />
          <br />
          You can find the most recent examples of my work below
        </ContactText>
        <ContactImagesSection>
          <ContactImageLink
            target="_blank"
            href="https://www.instagram.com/talluff/"
          >
            <InstaWhiteSVG />
            <InstaSVG />
          </ContactImageLink>
          <ContactImageLink target="_blank" href="https://talluff.itch.io/">
            <ItchSVG a="" />
          </ContactImageLink>
          <ContactImageLink
            target="_blank"
            href="https://www.linkedin.com/in/thomas-luff-32a880178"
          >
            <LinkedInSVG a="" />
          </ContactImageLink>
        </ContactImagesSection>
      </ContactSection>
      <Footer />
    </SectionContent>
  );
}

export default About;
