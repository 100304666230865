import { useState } from "react";
import "./App.css";
import NavBar from "./components/NavBar";
import SectionPanels from "./components/SectionPanels";
import About from "./components/About";
import Art from "./components/Art";
import Games from "./components/Games";
import Code from "./components/Code";
import { HomeBack, ContentContainer } from "./StyledApp.js";
import { animateScroll as scroll, scroller } from "react-scroll";
import animateScrollTo from "animated-scroll-to";

function App() {
  let selectedPanel = sessionStorage.getItem("selectedPanel");
  if (selectedPanel === null) selectedPanel = "";

  const [selected, setSelected] = useState(selectedPanel);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentImageSet, setCurrentImageSet] = useState([]);

  let clickButton = (index) => {
    if (selected === "" || selected !== index) {
      setSelected(index);
      sessionStorage.setItem("selectedPanel", index);
    } else {
      setSelected("");
      sessionStorage.setItem("selectedPanel", "");
    }
  };

  let scrollToElement = (anchor, offset = -100) => {
    scroller.scrollTo(anchor, {
      duration: 800,
      offset: offset,
      delay: 0,
      smooth: "easeOutQuad",
    });
  };

  let scrollTopPage = () => {
    scroll.scrollToTop({ duration: 800, smooth: "easeOutQuad" });
  };

  let calcHori = (elemId, parentId) => {
    let elemWidth = document
      .getElementById(elemId)
      .getBoundingClientRect().width;
    let windWidth = document.documentElement.clientWidth;
    let parLeftDisp = document
      .getElementById(parentId)
      .getBoundingClientRect().left;

    return (windWidth - elemWidth) / 2 - parLeftDisp;
  };

  let scrollCarousel = (elemId, parentId, vertId) => {
    let displacement = calcHori(elemId, parentId);
    scrollToElement(vertId);
    animateScrollTo(document.getElementById(elemId), {
      elementToScroll: document.getElementById(parentId),
      horizontalOffset: -displacement,
    });
  };

  const openLightbox = (imageSet, imageIndex) => {
    setCurrentImageSet(imageSet);
    setCurrentImageIndex(imageIndex);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setLightboxIsOpen(false);
  };

  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentImageIndex(currentImageIndex - 1);

  const gotoNext = () =>
    currentImageIndex + 1 < currentImageSet.length &&
    setCurrentImageIndex(currentImageIndex + 1);

  return (
    <div>
      <HomeBack selected={selected === "" ? true : false}>
        <NavBar
          selected={selected}
          scrollTopPage={scrollTopPage}
          clickButton={clickButton}
          scrollToElement={scrollToElement}
        />
        <SectionPanels
          selected={selected}
          clickButton={clickButton}
          scrollToElement={scrollToElement}
        />
      </HomeBack>

      <ContentContainer>
        <About selected={selected} />

        <Art
          selected={selected}
          animateScrollTo={animateScrollTo}
          scrollCarousel={scrollCarousel}
          openLightbox={openLightbox}
        />

        <Games selected={selected} openLightbox={openLightbox} />

        <Code selected={selected} />
      </ContentContainer>
      {/* <Lightbox
        isOpen={lightboxIsOpen}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        images={[]}
        currentIndex={currentImageIndex}
        onClose={closeLightbox}
        singleClickToZoom
        pageTransitionConfig={{
          from: { transform: "scale(0.75)", opacity: 0 },
          enter: { transform: "scale(1)", opacity: 1 },
          leave: { transform: "scale(0.75)", opacity: 0 },
          config: { mass: 1, tension: 320, friction: 32 },
        }}
        renderNextButton={({ canNext }) => (
          <LightboxArrowButton
            disabled={!canNext}
            onClick={() => gotoNext()}
            position="right"
          />
        )}
        renderPrevButton={({ canPrev }) => (
          <LightboxArrowButton
            disabled={!canPrev}
            onClick={() => gotoPrevious()}
            position="left"
          />
        )}
      /> */}
    </div>
  );
}

export default App;
