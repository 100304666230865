import {
  SectionContent,
  SectionContentTitle,
  SectionContentText,
  PortfolioSection,
  PortfolioInnerContainer,
  PortfolioTitle,
  PortfolioText,
  PortfolioImage,
  SlideshowBar,
  SlideshowPages,
  SlideshowLabel,
} from "../StyledApp";
import Footer from "./Footer";
import Dig1 from "../images/Art/Digital/Dig1.jpg";
import Dig2 from "../images/Art/Digital/Dig2.png";
import Dig3 from "../images/Art/Digital/Dig3.png";
import Dig4 from "../images/Art/Digital/Dig4.png";
import Dig5 from "../images/Art/Digital/Dig5.png";
import Dig6 from "../images/Art/Digital/Dig6.png";

import Trad1 from "../images/Art/Traditional/Trad1.jpg";
import Trad2 from "../images/Art/Traditional/Trad2.jpg";
import Trad3 from "../images/Art/Traditional/Trad3.png";
import Trad4 from "../images/Art/Traditional/Trad4.jpg";
import Trad5 from "../images/Art/Traditional/Trad5.png";
import Trad6 from "../images/Art/Traditional/Trad6.png";
import Trad7 from "../images/Art/Traditional/Trad7.png";
import Trad8 from "../images/Art/Traditional/Trad8.png";
import Trad9 from "../images/Art/Traditional/Trad9.png";
import Trad10 from "../images/Art/Traditional/Trad10.png";

function Art({ selected, animateScrollTo, scrollCarousel, openLightbox }) {
  const characterImages = [
    {
      src: Dig1,
      alt: "Running from dragon",
    },
    {
      src: Dig2,
      alt: "Fire",
    },
    {
      src: Dig3,
      alt: "Prince Armorious",
    },
    {
      src: Dig4,
      alt: "King Gobbo",
    },
    {
      src: Dig5,
      alt: "Go in for the kill",
    },
    {
      src: Dig6,
      alt: "Christmas raid",
    },
  ];
  const environmentImages = [
    {
      src: Trad1,
      alt: "Art",
    },
    {
      src: Trad2,
      alt: "Art",
    },
    {
      src: Trad3,
      alt: "Art",
    },
    {
      src: Trad4,
      alt: "Art",
    },
    {
      src: Trad5,
      alt: "Art",
    },
    {
      src: Trad6,
      alt: "Art",
    },
    {
      src: Trad7,
      alt: "Art",
    },
    {
      src: Trad8,
      alt: "Art",
    },
    {
      src: Trad9,
      alt: "Art",
    },
    {
      src: Trad10,
      alt: "Art",
    },
  ];
  const pixelImages = [
    {
      src: "https://img.itch.zone/aW1hZ2UvMTEyOTY1Ni82NTY3MDk2LnBuZw==/original/nqWkt6.png",
      alt: "Slime sync start",
    },
    {
      src: "https://img.itch.zone/aW1hZ2UvMjI5MzQ1MS8xMzU5Mzk4MC5wbmc=/original/TR99kO.png",
      alt: "Windows 10 Dark Mode Setting",
    },
    {
      src: "https://img.itch.zone/aW1hZ2UvMTI4NjUyNC83NTU5ODY2LnBuZw==/original/1F5dH4.png",
      alt: "Windows 10 Dark Mode Setting",
    },
  ];

  return (
    <SectionContent selected={selected === "Art"}>
      <SectionContentTitle selected={selected === "Art"}>
        Art
      </SectionContentTitle>
      {/* <SectionContentText>
        Self taught with roughly 8 years of digital and traditional personal
        experience. When making games I use my own art and animations and hope
        to try out new different styles soon. I am also enthusiastic about many
        different art forms including illustration, animation and concept art.
      </SectionContentText> */}
      <PortfolioSection selected={selected === "Art"}>
        <PortfolioInnerContainer id="CharPort">
          <div id="digital">
            <PortfolioTitle selected={selected === ""}>Digital</PortfolioTitle>
            <PortfolioText>
              {/* Character drawing is the area my art was initially drawn towards
              and have continued to improve with it, life drawing has helped
              also over the years and I still continue to practice this. The
              goal is to be able to draw professional looking character
              portraits and come up with concepts for making my games. */}
              Digital art is a favourite of mine and how I have spent the
              majority of my time drawing. I've drawn with lots of different
              softwares including Photoshop, Krita, Sai though currently I use
              Procreate the most. A lot of my style always seems to end up in
              fantasy, with lots of goblins, dragons, knights, wizards.. and I
              wouldn't have it any other way :{")"}.
            </PortfolioText>
          </div>
          {characterImages.map((charObj, index) => {
            return (
              <PortfolioImage
                id={"CharImg" + index}
                key={index}
                source={charObj.src}
                src={charObj.src}
                onClick={() => openLightbox(characterImages, index)}
              />
            );
          })}
        </PortfolioInnerContainer>
      </PortfolioSection>
      <SlideshowBar selected={selected === "Art"}>
        <SlideshowPages>Pages:</SlideshowPages>
        <SlideshowLabel
          onClick={() =>
            animateScrollTo(document.getElementById("characters"), {
              elementToScroll: document.getElementById("CharPort"),
              horizontalOffset: -150,
            })
          }
        >
          0
        </SlideshowLabel>
        {characterImages.map((charObj, index) => {
          return (
            <SlideshowLabel
              key={index}
              onClick={() =>
                scrollCarousel("CharImg" + index, "CharPort", "characters")
              }
            >
              {index + 1}
            </SlideshowLabel>
          );
        })}
      </SlideshowBar>
      <PortfolioSection selected={selected === "Art"}>
        <PortfolioInnerContainer id="EnvironPort">
          <div>
            <PortfolioTitle selected={selected === ""} id="traditional">
              Traditional
            </PortfolioTitle>
            <PortfolioText>
              {/* Sweeping landscapes and fantasy themes always amaze me,
              environments are something I have very limited experience in but
              being I'd love to improve my knowledge in mainly in colours and
              values. */}
              Working in traditional mediums is something that still feels newer
              to me and very enjoyable. I've been life drawing on and off for
              nearly a decade now and it's the biggest contributing factor to
              improving my drawings. There's limitations compared to digital,
              but from those there's also a clearer picture and much more room
              for creative decisions which I'm really enjoying.
            </PortfolioText>
          </div>
          {environmentImages.map((envObj, index) => {
            return (
              <PortfolioImage
                id={"EnvironImg" + index}
                source={envObj.src}
                key={index}
                src={envObj.src}
                onClick={() => openLightbox(environmentImages, index)}
              />
            );
          })}
        </PortfolioInnerContainer>
      </PortfolioSection>
      <SlideshowBar selected={selected === "Art"}>
        <SlideshowPages>Pages:</SlideshowPages>
        <SlideshowLabel
          onClick={() =>
            animateScrollTo(document.getElementById("environments"), {
              elementToScroll: document.getElementById("EnvironPort"),
              horizontalOffset: -150,
            })
          }
        >
          0
        </SlideshowLabel>
        {environmentImages.map((envObj, index) => {
          return (
            <SlideshowLabel
              key={index}
              onClick={() =>
                scrollCarousel(
                  "EnvironImg" + index,
                  "EnvironPort",
                  "environments"
                )
              }
            >
              {index + 1}
            </SlideshowLabel>
          );
        })}
      </SlideshowBar>
      <PortfolioSection selected={selected === "Art"}>
        <PortfolioInnerContainer id="PixelPort">
          <div>
            <PortfolioTitle selected={selected === ""} id="pixels">
              Pixel-Art
            </PortfolioTitle>
            <PortfolioText>
              {/* When starting making my own games pixel art always was a style
              that felt comfortable and fascinating to me. It's deconstructive
              but with lots expression available in every small decision. In
              terms of inspiration owl boy/megaman and terraria are examples I
              hold in really high regard and would love to incorporate elements
              into my own projects. */}
              Pixel art is a style I value really highly, it has been
              instrumental in creating some of my favourite gaming experiences
              and I hope to continue that with my own. My own work is often
              between 16-bit and 32-bit which I find gives me enough detail to
              work with but doesn't take away from the charm of being able to
              see the individual pixels.
            </PortfolioText>
          </div>
          {pixelImages.map((pixObj, index) => {
            return (
              <PortfolioImage
                id={"PixelImg" + index}
                source={pixObj.src}
                src={pixObj.src}
                key={index}
                onClick={() => openLightbox(pixelImages, index)}
              />
            );
          })}
        </PortfolioInnerContainer>
      </PortfolioSection>
      <SlideshowBar selected={selected === "Art"}>
        <SlideshowPages>Pages:</SlideshowPages>
        <SlideshowLabel
          onClick={() =>
            animateScrollTo(document.getElementById("pixels"), {
              elementToScroll: document.getElementById("PixelPort"),
              horizontalOffset: -150,
            })
          }
        >
          0
        </SlideshowLabel>
        {pixelImages.map((pixelObj, index) => {
          return (
            <SlideshowLabel
              key={index}
              onClick={() =>
                scrollCarousel("PixelImg" + index, "PixelPort", "pixels")
              }
            >
              {index + 1}
            </SlideshowLabel>
          );
        })}
      </SlideshowBar>
      <Footer />
    </SectionContent>
  );
}

export default Art;
