import {
  HomePage,
  HomePanel,
  HomePanelBack,
  SectionTitleContent,
  SectionFlourish,
  SectionActivate,
  SectionTitle,
} from "../StyledApp";

function SectionPanels({ selected, clickButton, scrollToElement }) {
  return (
    <HomePage>
      <HomePanel
        id="ArtPanel"
        selected={selected === "Art" ? true : false}
        order={1}
        height={
          selected === "Art" || selected === "Games" || selected === "Code"
            ? "70vh"
            : "80vh"
        }
      >
        <HomePanelBack onClick={() => clickButton("Art")} />
        <SectionTitleContent
          selected={selected === "Art" ? true : false}
          onClick={() => {
            if (selected !== "Art") clickButton("Art");
          }}
        >
          <SectionTitle
            selected={selected === "Art" ? true : false}
            onClick={() => clickButton("Art")}
          >
            Art
          </SectionTitle>
          <SectionActivate
            selected={selected === "Art" ? true : false}
            onClick={() => {
              if (selected === "Art") scrollToElement("digital");
            }}
          >
            Digital
          </SectionActivate>
          <SectionFlourish selected={selected === "Art" ? true : false} />
          <SectionActivate
            selected={selected === "Art" ? true : false}
            onClick={() => {
              if (selected === "Art") scrollToElement("traditional");
            }}
          >
            Traditional
          </SectionActivate>
          <SectionFlourish selected={selected === "Art" ? true : false} />
          <SectionActivate
            selected={selected === "Art" ? true : false}
            onClick={() => {
              if (selected === "Art") scrollToElement("pixels");
            }}
          >
            Pixel-Art
          </SectionActivate>
        </SectionTitleContent>
      </HomePanel>

      <HomePanel
        id="GamesPanel"
        selected={selected === "Games" ? true : false}
        order={2}
        height={
          selected === "Art" || selected === "Games" || selected === "Code"
            ? "70vh"
            : "80vh"
        }
      >
        <HomePanelBack onClick={() => clickButton("Games")} />
        <SectionTitleContent
          selected={selected === "Games" ? true : false}
          onClick={() => {
            if (selected !== "Games") clickButton("Games");
          }}
        >
          <SectionTitle
            selected={selected === "Games" ? true : false}
            onClick={() => clickButton("Games")}
          >
            Games
          </SectionTitle>
          <SectionActivate
            selected={selected === "Games" ? true : false}
            onClick={() => {
              if (selected === "Games") scrollToElement("Bomb Knight");
            }}
          >
            Bomb Knight
          </SectionActivate>
          <SectionFlourish selected={selected === "Games" ? true : false} />
          <SectionActivate
            selected={selected === "Games" ? true : false}
            onClick={() => {
              if (selected === "Games") scrollToElement("Energy Gain");
            }}
          >
            Energy Gain
          </SectionActivate>
          <SectionFlourish selected={selected === "Games" ? true : false} />
          <SectionActivate
            selected={selected === "Games" ? true : false}
            onClick={() => {
              if (selected === "Games") scrollToElement("Slime-Sync");
            }}
          >
            Slime Sync
          </SectionActivate>
          <SectionFlourish selected={selected === "Games" ? true : false} />
          <SectionActivate
            selected={selected === "Games" ? true : false}
            onClick={() => {
              if (selected === "Games") scrollToElement("Upward");
            }}
          >
            Upward
          </SectionActivate>
        </SectionTitleContent>
      </HomePanel>

      <HomePanel
        id="CodePanel"
        selected={selected === "Code" ? true : false}
        order={3}
        height={
          selected === "Art" || selected === "Games" || selected === "Code"
            ? "70vh"
            : "80vh"
        }
      >
        <HomePanelBack onClick={() => clickButton("Code")} />
        <SectionTitleContent
          selected={selected === "Code" ? true : false}
          onClick={() => {
            if (selected !== "Code") clickButton("Code");
          }}
        >
          <SectionTitle
            selected={selected === "Code" ? true : false}
            onClick={() => clickButton("Code")}
          >
            Code
          </SectionTitle>
          <SectionActivate
            selected={selected === "Code" ? true : false}
            onClick={() => {
              if (selected === "Code") scrollToElement("projects");
            }}
          >
            Projects
          </SectionActivate>
          <SectionFlourish selected={selected === "Code" ? true : false} />
          <SectionActivate
            selected={selected === "Code" ? true : false}
            onClick={() => {
              if (selected === "Code") scrollToElement("techStack");
            }}
          >
            Tech Stack
          </SectionActivate>
        </SectionTitleContent>
      </HomePanel>
    </HomePage>
  );
}

export default SectionPanels;
